
.class-top {
    display: flex;
    flex-wrap: wrap;
    padding:10px 20px;
    border-bottom: 1px solid #eaeaea;
    .top-left {
        width: 1%;
        flex: 1;
        .left-title {
            font-size: 14px;
            line-height: 40px;
            font-weight: 500;
        }
    }
}
.major-screening{
    padding:10px 0 10px 20px;
    .major-screening-title{
        font-size: 14px;
        margin-right:6px;
    }
}
::v-deep.el-table--border::after, ::v-deep .el-table--group::after,  ::v-deep.el-table::before {
    background-color: transparent;
}
::v-deep.el-table--medium td, ::v-deep.el-table--medium th{
    padding:14px 9px;
}
