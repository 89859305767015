
.choose-teacher {
    ::v-deep .el-dialog__body {
        padding: 0;
        border-top: 2px solid #E4E7ED;
        border-bottom: 2px solid #E4E7ED;
        .teacher-content {
            height: 420px;
            display: flex;
            .content-item {
                width: 50%;
                height: 100%;
                display: flex;
                flex-direction: column;
                &.content-right {
                    border-left: 2px solid #E4E7ED;
                }
                .content-title {
                    padding: 20px 20px 0;
                }
                .class-list {
                    height: 1%;
                    flex: 1;
                    padding: 10px 0 0;

                    ::v-deep .el-scrollbar__wrap {
                        overflow-x: hidden;
                    }
                }
                .teacher-list {
                    height: 1%;
                    flex: 1;
                    padding: 10px 0 0;

                    ::v-deep .el-scrollbar__wrap {
                        overflow-x: hidden;
                    }
                    .teacher-item {
                        display: flex;
                        align-items: center;
                        padding: 0 20px;
                        margin-bottom: 5px;

                        &:hover {
                            background-color: rgba(50, 150, 250, .1);
                        }
                        .item-left {
                            width: 1%;
                            flex: 1;
                            line-height: 30px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            align-items: center;
                            display: flex;
                        }
                        .item-right {
                            width: 30px;
                            height: 30px;
                            font-size: 18px;
                            text-align: center;
                            line-height: 30px;
                            cursor: pointer;
                            color: #ccc;
                        }
                    }
                }
            }
        }
    }
}
::v-deep.tree-level {
    .el-tree-node__content {
        height: 30px;
        padding-left: 20px!important;
        padding-right: 20px;
        margin-bottom: 5px;
        .el-checkbox {
            position: absolute;
            right: 12px;
        }
        .custom-tree-node {
            width: 250px;
            font-size: 0;
            display: flex;
            align-items: center;
            .node-title {
                font-size: 14px;
                line-height: 30px;
                max-width: 250px;
                display: inline-block;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 1%;
                flex: 1;
            }
        }
    }
}
.node-avatar {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
    background-size: cover;
    img {
        display: block;
        max-width: 100%;
        height: 20px;
    }
}
